import React from 'react';
import { NextPage } from 'next';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Main from '@/layouts/Main';
import styles from 'styles/jss/original/pages/error';
import classNames from 'classnames';

// @ts-expect-error
const useStyles = makeStyles(styles);

const error: NextPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Main>
        <div className={classes.main}>
          <div className={classes.section}>
            <div className={classes.container}>
              <h1 className={classes.titleLevel2}>404 NOT FOUND</h1>
              <h5 className={classes.description}>お探しのページは見つかりません</h5>
              <GridContainer>
                <GridItem
                  // @ts-expect-error
                  md={4}
                  sm={4}
                  className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}
                >
                  <Link href='/'>
                    <a>
                      {/* @ts-expect-error */}
                      <Button color='warning' round>
                        お探しのページは見つかりません
                      </Button>
                    </a>
                  </Link>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default error;
