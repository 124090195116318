// modifer: styles/jss/nextjs-material-kit-pro/pages/aboutUsStyle.js
import {
  page,
  main,
  section,
  container,
  mlAuto,
  mrAuto,
  title,
  description
} from 'styles/jss/original/base';

const styles = {
  page,
  main,
  mlAuto,
  mrAuto,
  container,
  section,
  title,
  titleLevel2: {
    ...title,
    textAlign: 'center',
    fontSize: '2.25rem',
    '@media (max-width: 640px)': {
      fontSize: '1.8rem'
    },
    '@media (max-width: 320px)': {
      fontSize: '1.6rem'
    }
  },
  description: {
    ...description,
    padding: '20px 0 30px',
    textAlign: 'center'
  },
  textCenter: {
    textAlign: 'center'
  }
};

export default styles;
